<template>
  <div class="main">
    <div class="top-select">
      <div class="top-title-text">自动红包设置</div>
      <van-cell center title="是否自动发送红包">
        <template #right-icon>
          <van-switch v-model="postData.openFlag" size="24" />
        </template>
      </van-cell>
    </div>
    <div v-if="postData.openFlag === true">
      <div @click="addItem()" class="add-button">
        <van-button icon="plus" type="info" size="small">新增一条规则</van-button>
      </div>
      <div v-for="(item,index) in postData.list" :key="index" class="config-list-box">
        <div @click="delItem(index)" class="del-button">删除</div>
        <div>
          <van-field v-model="item.watchTime" type="number" label="观看时长" placeholder="请输入时间" input-align="right">
            <template #button>
              <span>分钟</span>
            </template>
          </van-field>
          <van-field v-model="item.watchMoney" type="number" label="发放金额" placeholder="请输入金额" input-align="right">
            <template #button>
              <span style="padding-left: 15px">元</span>
            </template>
          </van-field>
        </div>
      </div>
    </div>
    <div style="height:80px"></div>
    <div class="bottom-box" @click="saveData">
      <div class="bottom-right">提交</div>
    </div>
  </div>
</template>
<script>
import { getRedConfig, saveRedConfig } from '@/api/live'
import { Toast } from 'vant';
import Cookies from "js-cookie";
export default {
  name: 'redConfig',
  components: {
  },
  data() {
    return {
      value: '',
      showPicker: false,
      userId: '', // 用户id
      postData: {
        shopUserid:'',
        openFlag: false,
        list: []
      },
      checked: false,
      queryPostData: {
        userId: '',
      },
    }},
  created() {
    let userId=Cookies.get('userId')
    if(!userId){
      this.$router.push({ name: 'liveList', query: {}});
      return
    }
    this.queryPostData.userId = userId
    this.postData.shopUserid = userId
    this.getData()
  },
  mounted:function(){
  },
  methods: {
    // 获取文章 图片等
    getData() {
      Toast.loading({message: '加载中...', forbidClick: true, duration: 0});
      getRedConfig(this.queryPostData).then(response => {
        Toast.clear()
        for (let i = 0; i <response.data.list.length; i++){
          response.data.list[i].watchMoney = this.floatDivide(response.data.list[i].watchMoney,100)
          response.data.list[i].watchTime = this.floatDivide(response.data.list[i].watchTime,60)
        }
        this.postData = response.data;
      })
    },
    // 新增一条
    addItem(){
      let newItem = {
        watchMoney:'',
        watchTime:''
      }
      this.postData.list.push(newItem)
    },
    // 删除一条
    delItem(index){
      if(this.postData.list.length<=1){
        Toast('请至少添加一条规则')
        return
      }
      this.postData.list.splice(index,1)
    },
    // 保存信息
    saveData() {
      Toast.loading({
        message: '保存中...',
        forbidClick: true,
      });
      for (let i = 0; i <this.postData.list.length; i++){
        this.postData.list[i].watchMoney = this.accMul(this.postData.list[i].watchMoney, 100)
        this.postData.list[i].watchTime = this.accMul(this.postData.list[i].watchTime, 60)
      }
      this.postData.shopUserid = Cookies.get('userId')
      console.log(this.postData)
      saveRedConfig(this.postData).then(response => {
        console.log(response)
        Toast.clear()
        if(response.code === 0 || response.code === 200){
          this.$router.push({ name: 'LiveList', query: {}});
          return
        }
      })
    },
    //乘法
    accMul(arg1,arg2) {
      var m=0,s1=arg1.toString(),s2=arg2.toString();
      try{m+=s1.split(".")[1].length}catch(e){console.log(e)}
      try{m+=s2.split(".")[1].length}catch(e){console.log(e)}
      return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)
    },
    // 乘法2
    floatMultiply(arg1, arg2){
      arg1 = Number(arg1);
      arg2 = Number(arg2);
      if ((!arg1 && arg1!==0) || (!arg2 && arg2!==0)) {
        return null;
      }
      arg1 = this.toNonExponential(arg1);
      arg2 = this.toNonExponential(arg2);
      var n1, n2;
      var r1, r2; // 小数位数
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      n1 = Number(arg1.toString().replace(".", ""));
      n2 = Number(arg2.toString().replace(".", ""));
      return n1 * n2 / Math.pow(10, r1 + r2);
    },
    // 除法
    floatDivide(arg1, arg2){
      arg1 = Number(arg1);
      arg2 = Number(arg2);
      if (!arg2) {
        return null;
      }
      if (!arg1 && arg1!==0) {
        return null;
      }else if(arg1===0) {
        return 0;
      }
      arg1 = this.toNonExponential(arg1);
      arg2 = this.toNonExponential(arg2);
      var n1, n2;
      var r1, r2; // 小数位数
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      n1 = Number(arg1.toString().replace(".", ""));
      n2 = Number(arg2.toString().replace(".", ""));
      return this.floatMultiply((n1 / n2), Math.pow(10, r2 - r1));
    },
    // 将科学计数法的数字转为字符串  说明：运算精度丢失方法中处理数字的时候，如果出现科学计数法，就会导致结果出错
    toNonExponential(num){
      if(num == null) {
        return num;
      }
      if(typeof num == "number") {
        var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
        return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
      }else {
        return num;
      }
    }
  }
}
</script>
<style scoped>
  .main{
    min-height: 100vh;
  }
  .top-title-text{
    color: #999a9a;
    font-size: 14px;
    padding: 20px 0 20px 20px;
  }
  .top-select{
    margin-bottom: 20px;
  }
  .config-list-box{
    background-color: #ffffff;
    margin: 10px;
    border-radius: 5px;
  }
  .del-button{
    color: #fa2c5c;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    padding: 10px 15px;
  }
  .bottom-box{
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #ffffff;
    padding: 20px 0;
  }
  .bottom-right{
    width: calc(100vw - 40px);
    height: 40px;
    line-height: 40px;
    background: linear-gradient(136deg, #5DA3FA 0%, #2586FF 100%);
    box-shadow: 3px 3px 8px 0px rgba(48, 51, 57, 0.2);
    border-radius: 2px;
    text-align: center;
    color: #ffffff;
  }
  .add-button{
    padding: 0 0 10px 10px;
  }
</style>
